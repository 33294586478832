@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Playfair+Display:wght@500&family=Poppins:wght@400;600&display=swap');

.home-banners{
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
}
.left-banner, .right-banner{
  display: contents;
}
/* .right-banner {
    display: flex;
    flex-direction: column;
  }
  
  .first-row {
    display: flex;
  }
  
  .second-row {
    display: flex;
  } */
  .right-banner {
    display: grid;
    grid-template-rows: auto 1fr; /* First row auto height, second row takes remaining space */
}

.first-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns within rows */
    grid-gap: 10px;
    row-gap: 10px; /* Adjust gap as needed */
}
.second-row{
  display: grid;
    /* grid-template-columns: 1fr 1fr; Two equal columns within rows */
    margin-top: 10px; 
}
.services{
  display: flex; /* Use Flexbox */
  align-items: center; /* Align items vertically */
  margin-left: 100px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.service {
  display: flex; /* Use Flexbox */
  align-items: center; /* Align items vertically */
  border-right: 2px solid black;
  padding: 20px;
  width: 300px;
}

.icon {
  margin-right: 10px; /* Adjust the spacing between icon and heading */
  font-size: 4rem;
}
.data .heading{
  font-size: 20px;
  font-weight: 700;
  font-family: 'DM Serif Display', serif;
}
.flashsales{
  display: grid;
  grid-template-columns: 1fr 2fr;
  
}
.flash h2{
  margin-left: 120px;
  width: 100%;
  max-height: 400px;
}
.newest{
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
}
.newest h2{
  text-align: center;
}
.top-seller{
  display: grid;
  grid-row: 2;
  grid-template-columns: 1fr 1fr 1fr;
}
.best h1{
  text-align: center;
}