.page_404 {
    margin-top: 60px;
    padding: 40px 0;
    background: #fff;
    font-family: "Arvo", serif;
  }
  
  .page_404 img {
    width: 100%;
  }
  .row {
    align-items: center;
    margin: 0;
  }
  .container {
    margin-top: 30px;
  }
  
  .four_zero_four_bg {
    background-image: url("https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif");
    background-repeat: no-repeat;
    height: 400px;
    background-position: center;
  }
  .four_zero_four_bg h1 {
    font-size: 80px;
  }
  .four_zero_four_bg h3 {
    font-size: 80px;
  }
  .text-center{
    text-align: center;
  }
  .link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    display: inline-block;
    border-radius: 10px;
  }
  .contant_box_404 {
    margin-top: -50px;
    text-align: center;
  }