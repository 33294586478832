.slick-slide {
    margin: 0 10px;
}
.slick-list {
    margin: 0;
    padding: 0 10px; /* Use padding instead of negative margin */
    margin-bottom: 0px;
}






