.flash-sale-card {
    border: 1px solid #ddd;
    padding: 20px;
    text-align: center;
    width: 250px;
    margin: 20px;
    display: inline-block;
    border-radius: 15px;
  }
  
  .flash-sale-timer {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .product-image {
    max-width: 100%;
    border-radius: 15px;
  }
  
  .product-title {
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
  }
  
  .product-price {
    font-size: 18px;
    margin-top: 10px;
  }
  
  .buy-now-button {
    background-color: #00ff4c;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 15px;
  }
  