.product {
  display: flex; /* Use flex to create a two-column layout */
  align-items: center; /* Vertically align the content in the middle */
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.product-image-container {
  flex: 1; /* Make the image container take up 1/2 of the available space */
  padding-right: 20px; /* Add some spacing between the image and details */
}

.product-image {
  max-width: 100%;
}

.product-details {
  flex: 1; /* Make the details container take up 1/2 of the available space */
}

.product-name {
  font-size: 18px;
  margin-top: 10px;
}

.product-rating {
  margin-top: 5px;
  color: #f39c12; /* Example color for the star rating */
}

.product-prices {
  margin-top: 10px;
}

.product-old-price {
  text-decoration: line-through;
  color: #888;
  margin-right: 10px;
}

.product-new-price {
  font-weight: bold;
  color: #e74c3c; /* Example color for the new price */
}

.product-categories {
  margin-top: 10px;
  color: #3498db; /* Example color for the categories */
}
