@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Playfair+Display:wght@500&family=Poppins:wght@400;600&display=swap');


.image-box {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .image-box-image {
    width: 100%;
    display: block;
    border-radius: 10px;
  }
  
  .image-box-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 60px;
  }
  
  .image-box-title {
    margin: 0;
    color: white;
    font-family: 'DM Serif Display', serif;
    font-size: 45px;
  }
  .image-box-description{
    margin: 10px 0;
    color: #fafafa;
    font-size: 20px;
  }
  
  .image-box-button {
    display: inline-block;
    padding: 13px 32px;
    background-color: #fafafa;
    color: rgb(8, 244, 47);
    text-decoration: none;
    border-radius: 45px;
    margin-top: 8px;
    font-family: 'DM Serif Display', serif;
    font-size: 16px;
  }
  